import _async_to_generator from "@swc/helpers/src/_async_to_generator.mjs";
import regeneratorRuntime from "/home/jenkins/agent/workspace/testale/grandiaziende-noprod-deploy/node_modules/next/dist/compiled/regenerator-runtime/runtime.js";
import { useAddressGeocoder } from "@vfit/shared/hooks";
export var checkIsValidProvince = function(province, italianProvinces) {
    var provinceNameLong = (province.match(/^[^(]+/) || " ")[0].trim();
    var provinceNameShort = (province.match(/\((.*?)\)/) || "")[1];
    if (provinceNameLong && provinceNameShort) {
        return italianProvinces.find(function(italianProvince) {
            return italianProvince.long === provinceNameLong && italianProvince.short === provinceNameShort;
        });
    }
    return undefined;
};
export var checkIsValidCity = function(city, italianMunicipalities) {
    var cityName = (city.match(/^[^(]+/) || " ")[0].trim();
    var provinceOfCity = (city.match(/\((.*?)\)/) || "")[1];
    if (cityName && provinceOfCity) {
        return italianMunicipalities.find(function(italianMunicipality) {
            return italianMunicipality.municipality === cityName && italianMunicipality.province === provinceOfCity;
        });
    }
    return undefined;
};
export var retrieveAddressString = function(inputs) {
    return "".concat(inputs.city, ", ").concat(inputs.postalCode, ", ").concat(inputs.stateOrProvince, ", ").concat(inputs.street, ", ").concat(inputs.streetNumber);
};
var GetAddressObjectFromString = function() {
    var _ref = _async_to_generator(regeneratorRuntime.mark(function _callee(text) {
        return regeneratorRuntime.wrap(function _callee$(_ctx) {
            while(1)switch(_ctx.prev = _ctx.next){
                case 0:
                    _ctx.t0 = Promise;
                    _ctx.next = 3;
                    return useAddressGeocoder(text);
                case 3:
                    _ctx.t1 = _ctx.sent;
                    return _ctx.abrupt("return", _ctx.t0.resolve.call(_ctx.t0, _ctx.t1));
                case 5:
                case "end":
                    return _ctx.stop();
            }
        }, _callee);
    }));
    return function GetAddressObjectFromString(text) {
        return _ref.apply(this, arguments);
    };
}();
/**
 *
 * @param addressString address retrieved from Form
 * @param setError Form method UseFormSetError<IAddressObject>
 * @param errors FieldErrorsImpl same key as IAddressObject
 * @returns This method returns the address formatted and validated by google
 */ // eslint-disable-next-line react-hooks/rules-of-hooks
export var retrieveAddressObjectFromString = function() {
    var _ref = _async_to_generator(regeneratorRuntime.mark(function _callee(addressString, setError, errors) {
        var addressGeocoder, pikedForMutation, picked, errorFocus;
        return regeneratorRuntime.wrap(function _callee$(_ctx) {
            while(1)switch(_ctx.prev = _ctx.next){
                case 0:
                    _ctx.next = 2;
                    return GetAddressObjectFromString(addressString).catch(function() {
                        return null;
                    });
                case 2:
                    addressGeocoder = _ctx.sent;
                    if (!addressGeocoder) {
                        _ctx.next = 8;
                        break;
                    }
                    pikedForMutation = function(param) {
                        var region = param.region, latitude = param.latitude, longitude = param.longitude, placeId = param.placeId;
                        return {
                            region: region,
                            latitude: latitude,
                            longitude: longitude,
                            placeId: placeId
                        };
                    }(addressGeocoder);
                    picked = function(param) {
                        var city = param.city, postalCode = param.postalCode, stateOrProvince = param.stateOrProvince, street = param.street, streetNumber = param.streetNumber;
                        return {
                            street: street,
                            streetNumber: streetNumber,
                            stateOrProvince: stateOrProvince,
                            city: city,
                            postalCode: postalCode
                        };
                    }(addressGeocoder);
                    if (Object.values(picked).some(function(el) {
                        return el === "";
                    })) {
                        errorFocus = false;
                        // eslint-disable-next-line array-callback-return
                        Object.entries(picked).filter(function(keyValueArray) {
                            if (keyValueArray[1] === "") {
                                var ref;
                                setError(keyValueArray[0], {
                                    message: errors === null || errors === void 0 ? void 0 : (ref = errors[keyValueArray[0]]) === null || ref === void 0 ? void 0 : ref.message
                                }, {
                                    shouldFocus: !errorFocus
                                });
                                errorFocus = true;
                            }
                            return {
                                picked: picked,
                                pikedForMutation: pikedForMutation
                            };
                        });
                    }
                    return _ctx.abrupt("return", {
                        picked: picked,
                        pikedForMutation: pikedForMutation
                    });
                case 8:
                    return _ctx.abrupt("return", null);
                case 9:
                case "end":
                    return _ctx.stop();
            }
        }, _callee);
    }));
    return function retrieveAddressObjectFromString(addressString, setError, errors) {
        return _ref.apply(this, arguments);
    };
}();
